@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,800;0,900;1,100;1,200;1,300;1,400;1,800;1,900&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

:root {
  --mainColor: #29335c;
  --mainColorLight: #5767aa;
  --secondaryColor: #db2b39;
  --textColor: #eee;
}

header,
.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  padding: 0 2rem;
  background: #000;
  color: #cfcfcf;
}

header nav a {
  margin: 0 1rem;
  color: var(--textColor);
  text-decoration: none;
  padding-left: 36px;
  font-weight: 300;
  font-size: 19px;
}

/* .main_banners::before{
  position: absolute;
  content: "";
  background-image: url(../Asset/images/Car-Banner.png);
  width: 100%;
  height: 100%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  bottom: 0;
  right: 0;
} */

nav a:hover {
  color: var(--secondaryColor);
}

header .nav-btn {
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  display: none;
  outline: none;
  color: var(--textColor);
  visibility: hidden;
  opacity: 0;
  font-size: 1.8rem;
}

header div,
.navbar nav {
  display: flex;
  align-items: center;
}
.main_banners {
  height: 100vh;
  background-image: url("/src/Asset/images/Banner.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  overflow-x: clip;
  display: flex;
  justify-content: center;
  align-items: center;
}
.navbar nav a:hover {
  color: #fff;
}
header.navbar {
  position: fixed;
  z-index: 10;
  width: 100%;
  top: 0;
}
.banner_content {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding-left: 26px;
}
.banner_title {
  background: linear-gradient(to bottom, #d2c050, #654b0e);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}
.banner_read {
  background-color: #426e43;
  padding: 9px;
  font-size: 20px;
  font-weight: 300;
  text-align: center;
  width: 40%;
  opacity: 0.9;
  color: #fff;
  border-radius: 40px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.banner_read:hover {
  color: #fff;
}

.banner_cars {
  position: absolute;
  transform: translate(75px, 50px);
}

.future_content {
  display: flex;
  justify-content: flex-end;
  align-items: self-end;
  min-height: 20px;
  padding: 0px 30px;
}
.future_cont p {
  font-size: 18px;
  opacity: 0.9;
  padding-top: 30px;
}
.future_content h1 {
  font-family: "Poppins", sans-serif;
  text-align: end;
  opacity: 0.8;
}
.car_mains {
  position: relative;
  overflow: hidden;
}
.future {
  background-image: url(../Asset/images/second_banner.png);
  color: #fff;
  width: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding-bottom: 60px;
}
.futures {
  position: relative;
  padding-bottom: 45px;
  z-index: 0;
}
.futures::before {
  position: absolute;
  content: "the future";
  font-size: 150px;
  z-index: -1;
  opacity: 0.1;
  font-weight: 800;
  color: #717171;
  bottom: 0px;
  transform: translate(-33px, 58px);
  left: 0px;
}
.banner ul li img {
  /* width: 22px;
  height: 28px; */
  opacity: 0.3;
  filter: invert(1);
}
.images_exterier {
  display: flex;
  justify-content: space-around;
}
.exterier_images img {
  width: 100%;
}
.design {
  position: relative;
}
.design::before {
  position: absolute;
  content: "";
  left: 7px;
  transform: translateY(-77px);
  width: 1.2px;
  top: 0;
  opacity: 0.3;
  height: 49px;
  background-color: white;
}
.social {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 27px;
}
.right_inter_img {
  z-index: 55;
}
.social nav li {
  height: 60px;
}
/* .car_frame {
position: relative;
} */
/* .car_frame img{
  width: 100%;
  position: absolute;
} */
.exterier_images {
  position: relative;
}
.exterier_images::before {
  position: absolute;
  content: "";
  right: 0;
  top: 0%;
  background-image: url(../Asset/images/Exterior_1.png);
  width: 53%;
  height: 100%;
  transform: translate(-33px, 10px);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 2s infinite alternate;
}

@keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}

.exterier_images::after {
  content: "Exterier";
  font-size: 160px;
  position: absolute;
  z-index: 0;
  opacity: 0.4;
  font-weight: 800;
  color: #717171;
  bottom: 0px;
  left: 0px;
}
.technology {
  overflow: hidden;
}
.tech_leter {
  position: relative;
}
.tech_leter::before {
  content: "technology";
  font-size: 90px;
  position: absolute;
  z-index: 3;
  opacity: 0.4;
  top: 58%;
  transform: rotate(-90deg);
  font-weight: 800;
  color: #717171;
  left: 73%;
}
.tech {
  background-image: url(../Asset/images/BG01.png);
  width: 100%;
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
}
.tech h1 {
  transform: rotate(-90deg);
}
.interier {
  background-image: url(../Asset/images/BG01.png);
  width: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  z-index: 0;
  overflow: hidden;
  position: relative;
}
.char {
  display: flex;
}
.right_inter_img img {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  z-index: 55;
}
.interier::before {
  content: "interier";
  font-size: 140px;
  position: absolute;
  z-index: 0;
  transform: translateY(39px);
  opacity: 0.4;
  font-weight: 800;
  color: #717171;
  bottom: 0px;
  left: 30%;
}
.founder,
.contact {
  position: relative;
}
.contact::before {
  content: "contact";
  font-size: 170px;
  position: absolute;
  z-index: 0;
  opacity: 0.1;
  transform: translateY(-42px);
  font-weight: 800;
  color: #717171;
  top: 0;
  right: 0;
}
.founder::before {
  content: "founder";
  font-size: 170px;
  position: absolute;
  z-index: 0;
  transform: translateY(39px);
  opacity: 0.1;
  font-weight: 800;
  color: #717171;
  top: 0;
  left: 0;
}
.contact::placeholder {
  color: #fff;
  opacity: 1;
}
.contact input {
  color: #fff;
  opacity: 1;
}
.submit_btn button {
  background-color: #c6a67d;
  border-radius: 50px;
  color: #fff;
  margin: 25px 48px;
  padding: 14px 39px;
}
.contact nav li {
  padding-bottom: 10px;
}
.contact nav li a {
  color: #717171;
  font-size: 21px;
  font-weight: 500;
}
.tech_shadows {
  background: rgb(9 8 9 / 97%);
  z-index: 1;
}
.tech_shadow {
  z-index: 1;
  opacity: 0.3;
  overflow-y: auto;
}
.active,
.tech_shadow:hover {
  opacity: 1.3;
}

.tech_shadow:hover::before {
  background: rgb(9 8 9 / 0%);
}
/* .tech_shadow::before {
  content: "";
  display: block;
  position: absolute;
  transition: 0.5s;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgb(9 8 9 / 67%);
  z-index: 1;
} */
.mobile_device {
  display: none;
}
.desktop_device {
  display: block;
}

/* Style the active class, and buttons on mouse-over */
.tech_shadow::before:hover {
  color: white;
  background-color: #262626;
}
.banner_title {
  font-size: 92px;
  font-weight: 500;
  width: 120%;
}

@media (max-width: 1024px) {
  header .nav-btn {
    visibility: visible;
    opacity: 1;
    display: block;
  }
  .social {
    display: none;
  }

  header nav {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 5;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    background-color: #000;
    transition: 1s;
    transform: translateY(-100vh);
  }

  header .responsive_nav {
    transform: none;
  }
  .banner_main,
  .interier_main {
    display: flex;
    flex-direction: column-reverse;
  }

  nav .nav-close-btn {
    position: absolute;
    top: 2rem;
    right: 2rem;
  }
  .driver_img {
    width: 300px;
    height: 354px;
    object-fit: cover;
  }
  .future_chaild {
    display: flex;
    justify-content: end;
  }
  .future_chaild_1 {
    display: flex;
    justify-content: start;
  }
  .car_view {
    display: flex;
    justify-self: center;
  }
  .cars {
    width: 100%;
    object-fit: fill;
    height: 220px;
  }
  .monider_img {
    width: 273px;
    height: 300px;
    object-fit: cover;
  }
  .banner_content {
    padding-bottom: 110px;
    gap: 5px;
  }
  .banner_read {
    padding: 11px;
    font-size: 18px;
    width: 44%;
  }
  nav a {
    font-size: 1.5rem;
  }
  .futures::before,
  .exterier_images::after,
  .interier::before,
  .founder::before,
  .contact::before {
    font-size: 70px;
  }
  .exterier_images::before {
    display: none;
  }
  .images_exterier {
    display: block;
  }
  .tech h1 {
    transform: rotate(0deg);
  }
  .tech_leter::before {
    font-size: 44px;
    top: 80%;
    left: 58%;
  }
  ul.design {
    display: flex;
    justify-content: space-evenly;
    padding: 20px 20px;
  }
  .social nav li {
    height: 0px;
    padding-left: 50px;
  }
  .mobile_device {
    display: block;
    overflow: hidden;
  }
  .desktop_device {
    display: none;
  }
  .contact::before {
    transform: translateY(8px);
  }
  .submit_btn button {
    margin: 30px 0px;
  }
  .form_type {
    padding-bottom: 15px;
  }
  .interier::before {
    transform: translateY(22px);
  }
  .futures::before {
    transform: translate(0px, 50px);
  }
  .banner_cars {
    position: absolute;
    transform: translate(45px, 0px);
  }
  .future_content {
    justify-content: left;
  }
  .exterier_mains .swiper-slide img {
    width: 100%;
    height: 600px;
    object-fit: fill;
  }
  header nav a {
    padding-left: 0px;
  }
  .banner_title {
    background: linear-gradient(to bottom, #d2c050, #7d6c44);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
  }
  .control_img img {
    float: left;
    width: 45%;
    margin: 1.66%;
  }
  .future_content h1 {
    text-align: start;
  }
  .banner_title {
    font-size: 50px;
    font-weight: 500;
  }
  .main_banners {
    height: 100vh;
    align-items: center;
    padding-bottom: 150px;
  }
  .banner_car {
    transform: scale(1.3);
  }
}

@media screen and (min-width: 1640px) and (max-width: 2600px) {
  .main_banners {
    display: block;
    padding-top: 450px;
  }
  .banner_cars {
    transform: translate(75px, -224px);
  }
  .container {
    margin: 0px 0px !important;
    width: unset !important;
    max-width: unset !important;
  }
}

@media screen and (min-width: 2100px) and (max-width: 2600px) {
  .banner_cars {
    transform: translate(75px, -388px);
  }
  .main_banners {
    display: block;
    padding-top: 500px;
  }
}
